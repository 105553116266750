import AdminCategoriesPageComponent from "./components/AdminCategoriesPageComponent";

import axios from "axios";

const fetchCategories = async (abctrl) => {
    const {data} = await axios.get("/api/categories", {
        signal: abctrl.signal,
    });
    return data
}

const deleteCategory = async (userId) => {
    const { data } = await axios.delete(`/api/categories/${userId}`);
    return data
}

const AdminCategoriesPage = () => {
  return <AdminCategoriesPageComponent fetchCategories={fetchCategories} deleteCategory={deleteCategory} />;
};

export default AdminCategoriesPage;